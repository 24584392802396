// extracted by mini-css-extract-plugin
export var badgeImage = "landing-module--badgeImage--f4335";
export var badgeLink = "landing-module--badgeLink--9763b";
export var content = "landing-module--content--0a588";
export var cover = "landing-module--cover--4207f";
export var details = "landing-module--details--cd290";
export var hero = "landing-module--hero--f5280";
export var image = "landing-module--image--633d0";
export var landing = "landing-module--landing--1d88c";
export var main = "landing-module--main--94ea2";
export var title = "landing-module--title--8ec17";